import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from "../components/RichText"
import { Breadcrumbs } from "../components/Breadcrumbs"
import get from "lodash.get"
import ProductCard from "../components/ProductCard"
import AuthorByline from "../components/AuthorByline"
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
  EmailIcon,
} from "react-share"
import "./Article.scss"

const ArticleTemplate = ({ data }) => {
  const article = data.contentfulArticle
  const products = get(data, "allContentfulProduct.nodes")
  const articleUrl = `https://toppicks.se/${article.category.slug}/${article.slug}`

  return (
    <Layout>
      <SEO title={article.title} description={article.summary.summary} />
      <Breadcrumbs
        parentPage={{
          shortTitle: article.category.name,
          urlPath: `/${article.category.slug}`,
        }}
        shortTitle={article.title}
        urlPath={`/${article.slug}`}
      />
      <article className="article-container">
        <div className="cover-image">
          <img
            src={article.coverImage.fixed.src}
            alt={article.coverImage.title}
          />
        </div>
        <h1 className="section-title">{article.title}</h1>
        <AuthorByline date={article.updatedAt} author={article.author} />
        <RichText content={article.content} />
        {products.map((product, i) => (
          <ProductCard key={i} product={product} />
        ))}
      </article>
      <div className="social-share">
        <FacebookShareButton url={articleUrl} quote={article.title}>
          <FacebookIcon />
        </FacebookShareButton>
        <PinterestShareButton
          url={articleUrl}
          media={article.coverImage.fixed.src}
          description={article.title}
        >
          <PinterestIcon />
        </PinterestShareButton>
        <TwitterShareButton url={articleUrl} title={article.title}>
          <TwitterIcon />
        </TwitterShareButton>
        <EmailShareButton url={articleUrl} subject={article.title}>
          <EmailIcon />
        </EmailShareButton>
      </div>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      slug
      title
      updatedAt
      summary {
        summary
      }
      content {
        json
      }
      coverImage {
        fixed(width: 680, height: 450) {
          src
        }
        title
      }
      category {
        name
        slug
      }
      author {
        name
        title
        avatar {
          title
          fixed(width: 50) {
            src
          }
        }
      }
    }
    allContentfulProduct(
      filter: { article: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        description {
          json
        }
        image {
          fixed(width: 280) {
            src
          }
          title
        }
        linkText
        name
        rating
        url
      }
    }
  }
`
