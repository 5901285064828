import React from "react"
import { RichText } from "../RichText"
import get from "lodash.get"
import "./styles.scss"

const ProductCard = ({ product }) => (
  <div className="product-card">
    <div className="product-display">
      <img
        src={get(product, "image.fixed.src")}
        alt={get(product, "image.title")}
      />
      <div className="cta-wrapper">
        <a
          className="cta"
          href={product.url}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <span>{get(product, "linkText", "Se erbjudande")}</span>
        </a>
      </div>
    </div>
    <div className="product-description">
      <a href={product.url} className="product-name underlined">
        {product.name}
      </a>
      <RichText content={product.description} />
    </div>
  </div>
)

export default ProductCard
