import React from "react"

export const dateToString = date => {
  const dateStringArr = new Date(date)
    .toLocaleDateString("sv", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .replace(/,/g, "")
    .split(" ")

  const day = dateStringArr[0]
  const month = dateStringArr[1]
  const dayPostFix = getNumberPostFix(day)

  return (
    <div className="author-byline_date">{`${day}${dayPostFix} ${month} ${dateStringArr[2]}`}</div>
  )
}

const getNumberPostFix = num => {
  const strNum = num.toString()
  const lastDigit = strNum.substr(strNum.length - 1)
  const secondLastDigit = strNum.substr(strNum.length - 2, 1)

  if (strNum.length >= 2 && secondLastDigit === "1") {
    return "e"
  }

  switch (lastDigit) {
    case "1":
      return "a"
    case "2":
      return "a"
    default:
      return "e"
  }
}
