import React from "react"
import { dateToString } from "../../helpers/date"
import "./styles.scss"

const AuthorByline = ({ author, date }) => {
  return (
    <div className="author-byline">
      <div className="author">
        <img src={author.avatar.fixed.src} alt={author.avatar.title} />
        <div className="heading">Skrivet av</div>
        <div className="name">{author.name}</div>
        <div className="title">{author.title}</div>
      </div>
      <div>{dateToString(date)}</div>
    </div>
  )
}

export default AuthorByline
